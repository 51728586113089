import React, { useEffect, useState } from 'react';
import { InvoiceDetails, Product } from '../../../schema/order';
import Table, { ColumnsType } from 'antd/es/table';
import { Button, Input, InputNumber, Modal, message } from 'antd';
import api from '../../../config/axios';
import { invoiceUpdateEmail, orderDetails, updateProduct } from '../../../config/api-routes';
import Spinner from '../../../layout/spinner';

type OrderDetailsTableProps = {
  products: Product[];
  invoice: InvoiceDetails;
  id: number;
  getOrderDetails: () => void;
  loading: boolean;
  isCancelled: boolean | undefined;
  isInvoiced: boolean;
  shipped: string;
};

interface EditableCellProps {
  value: number;
  onChange: (value: number) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({ value, onChange }) => {
  const handleChange = (e: any) => {
    const newValue = Number(e && e.toFixed(0));
    onChange(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <InputNumber
      min={0}
      // type='number'
      className='editable-input'
      value={Number(value)}
      onChange={handleChange}
    />
  );
};

interface EditableInputProps {
  value: number;
  onChange: (value: number) => void;
  type: string;
  amount: number;
}

const EditableInput: React.FC<EditableInputProps> = ({ value, onChange, type, amount }) => {
  const handleChange = (e: any) => {
    let newValue = Number(e && e.toFixed(2));
    if (type === 'Credits' || type === 'Special Discount') {
      if (newValue > amount) {
        newValue = amount;
        message.warning(`Cannot have ${type} more than bill amount`);
      }
    }
    onChange(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <InputNumber
      min={0}
      // type='number'
      addonBefore='$'
      className='editable-input'
      value={value}
      onChange={handleChange}
      precision={2}
    />
  );
};

function OrderDetailsTable({
  products,
  invoice,
  id,
  getOrderDetails,
  loading,
  isCancelled,
  isInvoiced,
  shipped,
}: OrderDetailsTableProps) {
  const [dataSource, setDataSource] = useState<Product[]>([]);
  const [staticDataSource, setStaticDataSource] = useState<Product[]>([]);
  const [initialDataSource, setInitialDataSource] = useState<Product[]>([]);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails>();
  const [shortageLoader, setShortageLoader] = useState<boolean>(false);
  const [scrapLoader, setScrapLoader] = useState<boolean>(false);
  const [inventoryLoader, setInventoryLoader] = useState<boolean>(false);
  const [invoicedModal, setInvoicedModal] = useState<boolean>(false);
  const [sendEmailModal, setSendEmailModal] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<number>(0);
  const [regenerateInvoiceLoader, setRegenerateInvoiceLoader] = useState<boolean>(false);
  const [showIsScrapPopup, setShowIsScrapPopup] = useState<boolean>(false);
  const [areEqualQuantity, setAreEqualQuantity] = useState<boolean>(false);

  useEffect(() => {
    if (products) {
      setInitialDataSource(products);
      setDataSource(products);
      setStaticDataSource(products);
      setInvoiceDetails(invoice);
    }
  }, [products, invoice]);

  const updateOrder = () => {
    const quantitiesAreEqual = areQuantitiesEqual(dataSource, initialDataSource);
    const quantitiesLessThanInitial = areQuantitiesLessThanInitial(dataSource, initialDataSource);

    if (quantitiesAreEqual) {
      setAreEqualQuantity(quantitiesAreEqual);
    } else {
      setAreEqualQuantity(quantitiesAreEqual);
    }

    if (quantitiesLessThanInitial) {
      if (!quantitiesAreEqual) {
        if (isInvoiced) {
          if (shipped != '') {
            updateOrderSubmit(true, false);
          } else {
            setShowIsScrapPopup(true);
          }
        } else {
          if (shipped != '') {
            updateOrderSubmit(true, false);
          } 
          else {
            setShowIsScrapPopup(true);
          // setSendEmailModal(true);
          }
        }
      }
    } else {
      updateOrderSubmit();
    }
  };

  const columns: ColumnsType<Product> = [
    {
      title: 'Variety',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 250,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
    },
    {
      title: 'Price Per Piece',
      dataIndex: 'pricePerPiece',
      key: 'pricePerPiece',
      align: 'right',
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
      width: 100,
    },
    {
      title: 'Ordered',
      dataIndex: 'orderedQuantity',
      key: 'orderedQuantity',
      align: 'right',
      width: 200,
    },
    {
      title: 'Shipment',
      dataIndex: 'shipment',
      align: 'right',
      key: 'shipment',
      width: 200,
      render: (text: string, record: Product) => {
        return (
          <span>
            {!isCancelled && shipped == '' && (
              <Input
                maxLength={1}
                pattern='^[a-zA-Z0-9]+$'
                value={text ? text.toUpperCase() : text}
                onChange={(e) => handleShipmentChange(record.id!, e)}
                style={{ width: '35px' }}
              />
            )}
            {(isCancelled || shipped != '') && text}
          </span>
        );
      },
    },
    {
      title: 'Available',
      dataIndex: 'quantity',
      align: 'right',
      key: 'quantity',
      width: 200,
      render: (text: number, record: Product) => {
        return (
          <span>
            {!isCancelled && (
              <EditableCell
                value={text}
                onChange={(value) => handleQuantityChange(record.id!, value)}
              />
            )}
            {isCancelled && text}
          </span>
        );
      },
    },
    {
      title: 'Cost',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'right',
      width: 150,
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
    },
  ];

  const handleQuantityChange = (id: number, value: number) => {
    setRecordId(id);
    const newData = dataSource.map((item) => {
      if (item.id === id) {
        const staticItem = staticDataSource.find((item) => item.id === id);
        if (item.pricePerPiece) {
          if (
            shipped != '' &&
            item.quantity != null &&
            staticItem != null &&
            staticItem.quantity != null &&
            value > staticItem.quantity
          ) {
            return {
              ...item,
              quantity: staticItem.quantity,
              cost: staticItem.quantity * item.pricePerPiece,
            };
          } else {
            return { ...item, quantity: value, cost: value * item.pricePerPiece };
          }
        }
      }
      return item;
    });
    setDataSource(newData);
  };

  const handleShipmentChange = (id: number, event: any) => {
    const value = event.target.value;
    setRecordId(id);
    const newData = dataSource.map((item) => {
      if (item.id === id) {
        if (item.pricePerPiece) {
          return { ...item, shipment: value };
        }
      }
      return item;
    });

    setDataSource(newData);
  };

  const handleInvoicedOk = () => {
    setRegenerateInvoiceLoader(true);
    api
      .get<never, any>(invoiceUpdateEmail + '/' + id)
      .then((res: any) => {
        setRegenerateInvoiceLoader(false);
        message.success(res?.message);
      })
      .catch((error) => {
        setRegenerateInvoiceLoader(false);
        message.error(error?.response?.data?.message);
      });
    setInvoicedModal(false);
  };

  const handleInvoicedCancel = () => {
    setInvoicedModal(false);
  };

  const handleEmailCancel = () => {
    setSendEmailModal(false);
  };
  const handleNoEmailSend = () => {
    updateOrderWithEmail(false);
  };
  const handleEmailOk = () => {
    updateOrderWithEmail(true);
  };

  const handleUpdateWithScrap = () => {
    updateOrderSubmit(true, false);
  };
  const handleUpdateWithReduction = () => {
    updateOrderSubmit(false, false);
  };
  const handleUpdateWithInventory = () => {
    updateOrderSubmit(false, true);
  };

  const areQuantitiesEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].quantity !== arr2[i].quantity) {
        return false; // Quantity values are different
      }
    }

    return true; // All quantity values are the same
  };

  const areQuantitiesLessThanInitial = (dataSource: any, initialDataSource: any) => {
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].quantity < initialDataSource[i].quantity) {
        return true;
      }
    }
    return false;
  };

  const updateOrderWithEmail = (sendEmail: boolean) => {
    setSendEmailModal(false);
    setShowIsScrapPopup(true);
    setSendEmail(sendEmail);
  };

  const updateOrderSubmit = (isScrap?: boolean, addToInventory?: boolean) => {
    const payload = {
      id: recordId,
      products: dataSource,
      shippingAmount: invoice.shippingAmount,
      creditAmount: invoice.creditAmount,
      specialDiscountAmount: invoice.specialDiscountAmount,
      isEmail: sendEmail,
      isScrap: isScrap ? isScrap : false,
      addToInventory: addToInventory ? addToInventory : false,
    };

    if (!addToInventory) {
      isScrap ? setScrapLoader(true) : setShortageLoader(true);
    }
    addToInventory ? setInventoryLoader(true) : setInventoryLoader(false);

    api
      .put<never, any>(orderDetails + `/${id}`, payload)
      .then((res: any) => {
        if (res) {
          setShortageLoader(false);
          setScrapLoader(false);
          setInventoryLoader(false);
          getOrderDetails();
          setSendEmailModal(false);
          setShowIsScrapPopup(false);
          setSendEmailModal(false);
          message.success(res.message);
          if (isInvoiced) {
            setInvoicedModal(true);
          }
        }
      })
      .catch((error: any) => {
        setShortageLoader(false);
        setScrapLoader(false);
        setInventoryLoader(false);
        message.error(error?.response?.data?.message);
      });
  };

  const onCancel = () => {
    setShowIsScrapPopup(false);
  };

  if (regenerateInvoiceLoader) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='px-3 pt-3'>
      <Modal
        title='Invoice has already been generated'
        open={invoicedModal}
        onOk={handleInvoicedOk}
        onCancel={handleInvoicedCancel}
        footer={[
          <Button key='no' onClick={handleInvoicedCancel}>
            No
          </Button>,
          <Button key='yes' type='primary' onClick={handleInvoicedOk}>
            Yes
          </Button>,
        ]}
      >
        Do you want to send invoice?
      </Modal>

      <Modal
        open={sendEmailModal}
        onOk={handleEmailOk}
        onCancel={handleEmailCancel}
        footer={[
          <Button key='no' onClick={handleNoEmailSend}>
            No
          </Button>,
          <Button key='yes' type='primary' onClick={handleEmailOk}>
            Yes
          </Button>,
        ]}
      >
        Do you want to send email to the user?
      </Modal>

      <Table
        loading={loading || shortageLoader}
        columns={columns}
        dataSource={dataSource}
        rowKey='id'
        pagination={false}
        summary={() => (
          <>
            <Table.Summary.Row key='subTotal'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>SubTotal :</b> <small>(Pre-Tax)</small>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                ${invoice.subTotal.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row key='discount'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>Discount ({invoice.discount.toFixed(2)}%) :</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                ${invoice.discountAmount.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row key='credits'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>Credits :</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                ${invoice.creditAmount.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row key='specialDiscounts'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>Special Discount :</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                {!isCancelled && (
                  <EditableInput
                    value={Number(invoice.specialDiscountAmount.toFixed(2))}
                    onChange={function (value: number): void {
                      invoice.specialDiscountAmount = value;
                      setInvoiceDetails({ ...invoice });
                    }}
                    type='Special Discount'
                    amount={
                      invoice.subTotal +
                      invoice.shippingAmount -
                      invoice.discountAmount -
                      invoice.creditAmount
                    }
                  />
                )}
                {isCancelled && invoice.specialDiscountAmount.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row key='shipping'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>Shipping :</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                {!isCancelled && (
                  <EditableInput
                    value={Number(invoice.shippingAmount.toFixed(2))}
                    onChange={function (value: number): void {
                      invoice.shippingAmount = value;
                      setInvoiceDetails({ ...invoice });
                    }}
                    type='Shipping'
                    amount={invoice.grandTotal}
                  />
                )}
                {isCancelled && invoice.shippingAmount.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row key='gst/hst'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>
                  {(invoice.tax.gst.rate != 0 &&
                    invoice.tax.gst.taxType + ' (' + invoice.tax.gst.rate + '%):') ||
                    (invoice.tax.hst.rate != 0 &&
                      invoice.tax.hst.taxType + ' (' + invoice.tax.hst.rate + '%):')}
                </b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                <b>
                  $
                  {(invoice.tax.gst.rate != 0 && invoice.tax.gst.amount.toFixed(2)) ||
                    (invoice.tax.hst.rate != 0 && invoice.tax.hst.amount.toFixed(2))}
                </b>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            {invoice.tax.pstqst.taxType && (
              <Table.Summary.Row key='pst'>
                <Table.Summary.Cell index={0} colSpan={7}>
                  <b>{invoice.tax.pstqst.taxType + ' (' + invoice.tax.pstqst.rate + '%):'}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1} align='right'>
                  <b>${invoice.tax.pstqst.amount.toFixed(2)}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}

            <Table.Summary.Row key='grandTotal'>
              <Table.Summary.Cell index={0} colSpan={7}>
                <b>Grand Total :</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1} align='right'>
                <b>${invoice.grandTotal.toFixed(2)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
      {
        <div className='d-flex justify-content-end pt-2'>
          {!isCancelled && !shortageLoader && !loading && (
            <Button type='primary' onClick={updateOrder} loading={loading || shortageLoader}>
              Update
            </Button>
          )}
        </div>
      }

      <Modal
        open={showIsScrapPopup}
        title='Update with'
        onCancel={onCancel}
        onOk={handleUpdateWithScrap}
        footer={[
          <Button
            key='isReduction'
            loading={shortageLoader}
            disabled={scrapLoader || inventoryLoader}
            type='primary'
            onClick={handleUpdateWithReduction}
          >
            Shortage
          </Button>,
          <Button
            key='isScrap'
            loading={scrapLoader}
            disabled={shortageLoader || inventoryLoader}
            type='primary'
            onClick={handleUpdateWithScrap}
          >
            Scrap
          </Button>,
          <Button
            key='addToInventory'
            loading={inventoryLoader}
            disabled={shortageLoader || scrapLoader}
            type='primary'
            onClick={handleUpdateWithInventory}
          >
            Inventory
          </Button>,
        ]}
      ></Modal>
    </div>
  );
}
export default OrderDetailsTable;
